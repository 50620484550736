import { ENABLE_MAGIC_LINK_BUTTON, SHOW_MAGIC_LINK_MODAL } from './types';

const initialState = {
  showMagicLinkModal: false,
  submitEnabled: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_MAGIC_LINK_MODAL: {
      const { showMagicLinkModal } = payload;
      return { ...state, showMagicLinkModal };
    }
    case ENABLE_MAGIC_LINK_BUTTON: {
      const { submitEnabled } = payload;
      return { ...state, submitEnabled };
    }
    default: {
      return state;
    }
  }
};
