/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FOMOText, RemoteConfigState } from './types';

export type RemoteConfigResponse = {
  [key in keyof RemoteConfigState]?: RemoteConfigState[key] | string;
};

const defaultFomoStep: FOMOText = {
  image: '',
  title: '',
  description: '',
  items: [],
  cancelButtonText: '',
  keepButtonText: '',
};

const initialState: RemoteConfigState = {
  emphasizeGiftCard: false,
  giftCardSpecialIconURL: 'https://assets.zoolife.tv/animated-gift-green.gif',
  fomoTexts: {
    step1: defaultFomoStep,
    step2: defaultFomoStep,
  },
};

// TODO: remove frontend type conversion when API is updated
const convertValue = <T>(val?: any) => {
  if (typeof val !== 'string') {
    return val as T;
  }
  try {
    return JSON.parse(val) as T;
  } catch {
    return val as unknown as T;
  }
};

const remoteConfigSlice = createSlice({
  name: 'remoteConfig',
  initialState,
  reducers: {
    setRemoteConfig: (_, { payload }: PayloadAction<RemoteConfigResponse>) => {
      const parsed = Object.fromEntries(
        Object.entries(payload).map(([key, val]) => [key, convertValue(val)]),
      ) as unknown as RemoteConfigState;
      parsed.emphasizeGiftCard ??= initialState.emphasizeGiftCard;
      parsed.giftCardSpecialIconURL ||= initialState.giftCardSpecialIconURL;
      return parsed;
    },
  },
});

export default remoteConfigSlice;
