import {
  differenceInDays,
  format,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  intervalToDuration,
  parseISO,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import OneSignal from 'react-onesignal';

import { isDev } from '@/lib/utils';
import { getClientId } from '@/shared/ga';

export const MULTI_FREE_HABITAT_COUNT = 2;

export const secondMs = 1000;
export const minuteMs = secondMs * 60;
export const hourMs = minuteMs * 60;
export const dayMs = hourMs * 24;
export const weekMs = dayMs * 7;

/**
 * Checks if a given URL is valid.
 *
 * @param {string} url - The URL to be validated.
 * @return {boolean} Returns true if the URL is valid, false otherwise.
 */
export const isValidUrl = (url) => {
  try {
    return !!new URL(url.startsWith('/') ? `${window.location.origin}${url}` : url);
  } catch {
    return false;
  }
};

export const emailRegex = /^[A-Z0-9+_.-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+$/gi;
// eslint-disable-next-line no-useless-escape
export const passwordRegex = /^(?=.*[a-zA-Z0-9!@#$%^&*()\[\]?,.<>:;'"|\\\/-_+=])(?!.*\s).{8,50}/gm;

export const iOSDevice = () => typeof window !== 'undefined' && !!navigator.userAgent.match(/iPhone|iPod|iPad/);
export const androidDevice = () => typeof window !== 'undefined' && !!navigator.userAgent.match(/Android/i);

export { isDev } from '@/lib/utils';
export const getHomeURL = () => (!isDev() ? 'https://zoolife.tv' : 'https://zoolife.live');

export const getDeviceType = () => {
  if (typeof window === 'undefined') {
    return 'desktop';
  }

  const ua = navigator.userAgent;
  if (
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'tablet';
  }

  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'phone';
  }

  return 'desktop';
};

export const isPhone = () => getDeviceType() === 'phone';
export const isEmbedded = () => typeof window !== 'undefined' && window.self !== window.parent;

export const getDesktopOrMobile = () => {
  const type = getDeviceType();

  if (type === 'tablet' || type === 'phone') {
    return 'mobile';
  }

  return 'desktop';
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Generate title
 *
 * @param {string} part
 * @returns {string}
 */
export const generateTitle = (part) => (part ? `zoolife - ${part}` : 'zoolife');

/**
 * Formats the age based on the given date of birth.
 *
 * @param {string} dateOfBirth - The date of birth in ISO format.
 * @return {string} The formatted age.
 */
export const formatAge = (dateOfBirth) =>
  formatDistanceToNowStrict(parseISO(dateOfBirth), { addSuffix: false, roundingMethod: 'floor' });

/**
 * Logs a page view to the analytics tool if window is defined.
 *
 * @param {string} page - The page to log the view for.
 */
export const logPageView = (page) => {
  if (typeof window !== 'undefined') {
    const context = {
      referrer: '',
      page: {
        referrer: '',
      },
    };

    if (window.analytics) {
      if (page) {
        window.analytics.page({
          path: `${page}`,
          url: `${page}`,
          ...context,
        });
      } else {
        window.analytics.page();
      }
    }
  }
};

/**
 * Function to identify a user for analytics.
 *
 * @param {Object} user - The user object containing _id and role.
 * @param {string} user._id
 * @param {string} user.role
 */
export const identifyUser = (user) => {
  const { _id: userId, role } = user;
  if (window.analytics) {
    window.analytics.identify(userId, {
      role,
    });
  }
};

const partners = {
  '/oranapark': 'orana',
  '/orana': 'orana',
  '/torontozoo': 'torontozoo',
  '/sbzoo': 'sbzoo',
  '/phoenixherp': 'phoenixherp',
  '/lpks': 'lpks',
  '/lpzoo': 'lpzoo',
  '/northumberlandzoo': 'northumberlandzoo',
  '/akronzoo': 'akronzoo',
};

export const logAndGetCampaignData = async () => {
  try {
    const { referrer } = document;
    const { searchParams, pathname } = new URL(document.location);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmHabitat = searchParams.get('utm_habitat');
    const partner = searchParams.get('partner');
    const referralData = {
      utmSource,
      utmMedium,
      utmCampaign,
      utmHabitat,
      partner,
      referrer,
    };

    // when we move all the partner pages to webflow, we can remove this if statement:
    if (Object.keys(partners).includes(pathname)) {
      referralData.partner = partners[pathname];
    }
    const storedReferralData = JSON.parse(localStorage.getItem('referralData')) ?? {};
    const {
      utmSource: localUTMSource,
      utmMedium: localUTMMedium,
      utmCampaign: localUTMCampaign,
      utmHabitat: localUTMHabitat,
      referrer: localReferrerCampaign,
      partner: localPartner,
    } = storedReferralData;

    if (!isEqual(storedReferralData, referralData)) {
      let clientId = null;
      try {
        clientId = await getClientId();
      } catch (err) {
        console.error('GA is not able to create client ID', err);
      }
      const finalRefData = {
        utmSource: localUTMSource || utmSource,
        utmMedium: localUTMMedium || utmMedium,
        utmCampaign: localUTMCampaign || utmCampaign,
        utmHabitat: utmHabitat || localUTMHabitat,
        // Preserve referrer if referrer is coming from Google OAuth
        referrer: referrer?.indexOf('accounts.google') >= 0 ? localReferrerCampaign : localReferrerCampaign || referrer,
        partner: localPartner || referralData.partner,
        gaClientID: clientId,
      };
      localStorage.setItem('referralData', JSON.stringify({ ...storedReferralData, ...finalRefData }));
      return { ...storedReferralData, ...finalRefData };
    }
    return storedReferralData;
  } catch (err) {
    console.error('Error setting up referral data on local storage', err);
    return {};
  }
};

export const getCampaignData = () => {
  try {
    return JSON.parse(localStorage.getItem('referralData')) || {};
  } catch (err) {
    console.error('Error getting referral data on local storage', err);
    return {};
  }
};

/**
 * Get config by key
 *
 * @param {Array<{ configKey: string }>} configs
 * @param {string} key
 * @returns {any}
 */
export const getConfig = (configs = [], key) => configs.find(({ configKey }) => configKey === key) || {};

export const loadPage = (path, keepQueryString) => {
  if (window.iframeContext) {
    window.open(
      `${window.location.origin}${path}${keepQueryString ? window.location.search : ''}`,
      window.iframeContext.linkTarget,
    );
  } else if (isEmbedded()) {
    window.top.location.href = `${path}${keepQueryString ? window.location.search : ''}`;
  } else {
    window.location.href = `${path}${keepQueryString ? window.location.search : ''}`;
  }
};

/**
 * Formats the given time in seconds to a video duration format.
 *
 * @param {number} timeInSeconds - The time in seconds to be formatted.
 * @return {string} The formatted video duration string.
 */
export const formatSecondsToVideoDuration = (timeInSeconds) => {
  const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: timeInSeconds * 1000 });
  return `${hours > 0 ? `${`0${hours}`.slice(-2)}:` : ''}${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`;
};

/**
 * Checks if a habitat is unlocked based on the user's subscription status.
 *
 * @param {object} userSubscription - The user's subscription information.
 * @param {boolean} [userSubscription.isFreemium]
 * @param {string[]} [userSubscription.freeHabitats]
 * @param {string} habitatId - The ID of the habitat to check.
 * @return {boolean} Returns true if the habitat is unlocked, false otherwise.
 */
export const isHabitatUnlocked = (userSubscription, habitatId) => {
  const { isFreemium, freeHabitats } = userSubscription;
  return !isFreemium || freeHabitats?.includes(habitatId);
};

/**
 * Returns a promise that resolves with the OneSignal notification permission status after a timeout of 1000ms.
 *
 * @return {Promise<string>} A promise that resolves with the OneSignal notification permission status.
 */
export const notificationPermissionStatus = () =>
  new Promise((resolve) => {
    try {
      const timeout = setTimeout(() => resolve(''), 1000);
      OneSignal.getNotificationPermission().then((res) => {
        clearTimeout(timeout);
        resolve(res);
      });
    } catch (e) {
      resolve('');
    }
  });

export const requestNotificationPermission = async () => {
  await OneSignal.showNativePrompt();
};

export const isLandscape = () =>
  typeof window !== 'undefined' ? window.matchMedia('(orientation:landscape)').matches : null;
export const isPortrait = () => (typeof window !== 'undefined' ? window.matchMedia('(orientation:portrait)').matches : null);

/**
 * Converts milliseconds to a human-readable format of hours, minutes, and seconds.
 *
 * @param {number} millis - the number of milliseconds to convert
 * @return {string} the human-readable format of the input milliseconds
 */
export const msToReadable = (millis) => {
  const hour = Math.floor(millis / hourMs);
  const minutes = Math.floor((millis % hourMs) / minuteMs);
  const seconds = ((millis % minuteMs) / secondMs).toFixed(0);
  return `${hour > 0 ? `${hour}:` : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

/**
 * this function is not in use yet, mainly to define/unify who can see what logic
 *
 * @param {string} role - The role of the user
 * @param {string | null} validUntil - The validity period of the user's access
 * @param {string} habitatId - The ID of the habitat being accessed
 * @param {string[]} freeHabitats - Array of IDs of free habitats
 * @return {boolean} Whether the user can access the habitat
 */
export const canAccessHabitat = (role, validUntil, habitatId, freeHabitats) => {
  // Admins, Partners, Hosts should have access to all habitats
  if (['admin', 'partner', 'host'].includes(role)) {
    return true;
  }

  // Free habitats should be accessible even after subscription is done
  if (freeHabitats.includes(habitatId)) {
    return true;
  }

  // if not free habitat, and validUntil is not in the future
  if (['vip', 'user'].includes(role) && validUntil && Date.parse(validUntil) >= Date.now()) {
    return true;
  }

  return false;
};

/**
 * Calls the Facebook pixel tracking function with the given action and event.
 *
 * @param {string} action - The action to be tracked.
 * @param {string} event - The event to be tracked.
 */
export const trackFB = (action, event) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(action, event);
  }
};

/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text - The text to be copied.
 * @param {(success: boolean) => void} [onChange] - (Optional) A callback function to be called after the text is copied. It will be passed a boolean indicating whether the copy operation was successful.
 */
export const copyToClipboard = (text, onChange = () => {}) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => onChange(true))
      .catch((err) => console.error('Error while copying via navigator.clipboard', err));
  } else {
    // if clipboard is not supported, attempt to use old alternative (deprecated)
    console.warn('clipboard is not supported, copy via execCommand.');
    const elem = document.createElement('input');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();

    try {
      document.execCommand('copy');
      onChange(true);
    } catch (err) {
      console.error('Error while copying to clipboard', err);
    } finally {
      document.body.removeChild(elem);
    }
  }
};

/**
 * Sets local storage with ODTP if certain conditions are met.
 *
 * @param {object} [subscription] - The subscription object to check for certain conditions.
 * @param {string} [subscription.productName] - The subscription object to check for certain conditions.
 * @param {string} [subscription.productId] - The subscription object to check for certain conditions.
 */
export const setLocalStorageWithODTP = (subscription) => {
  if (subscription) {
    try {
      if (localStorage.getItem('odtp')) {
        return;
      }
      if (
        ['tz-bundle-membership-offline-day-ticket', 'tz-bundle-membership-online-day-ticket'].includes(
          subscription.productName,
        )
      ) {
        const odtpValue = {
          productId: subscription.productId,
          timestamp: new Date().toISOString(),
        };
        localStorage.setItem('odtp', JSON.stringify(odtpValue));
      }
    } catch (error) {
      console.error('Unable to access localStorage:', error);
    }
  }
};

/**
 * this function will format a date to either 'x ago' format or a formatted timestamp
 *
 * @param {number|Date} date - The date to be formatted.
 * @param {boolean} [shortFormat=false] - Indicates whether to use the short format or not.
 * @return {string} The formatted date string.
 */
export const formatTimeAgoOrTimestamp = (date, shortFormat = false) => {
  const formatDistanceToNowStrictLocale = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
  };
  const shortEnLocale = {
    formatDistance: (token, count) => formatDistanceToNowStrictLocale[token].replace('{{count}}', count),
  };

  if (differenceInDays(new Date(), date) < 1) {
    if (shortFormat) {
      return `${formatDistanceToNowStrict(date, { addSuffix: false, locale: shortEnLocale })} ago`;
    }
    return `${formatDistanceToNow(date, { addSuffix: true })}`;
  }

  return format(date, 'dd-MM-yy hh:mma');
};

export const ABTestsObj = Object.freeze({});

/**
 * Checks if the input cohorts array contains any of the target cohort IDs.
 *
 * @param {Array<{ cohortId: string }>} cohorts - The array of cohorts to check.
 * @return {boolean} True if any of the target cohort IDs are present in the input array, otherwise false.
 */
export const isInPushPermissionCohort = (cohorts) => {
  const targetCohortIds = ['65d666ee2ac83c4df405edab', '65e258980123b64b13b6d22e']; // [development, production]
  return cohorts.some((cohort) => targetCohortIds.includes(cohort.cohortId));
};

/**
 * Check if any of the cohorts are in the install app cohort.
 *
 * @param {Array<{ cohortId: string }>} cohorts - Array of cohorts to check
 * @return {boolean} Returns true if any cohort is in the install app cohort
 */
export const isInInstallAppCohort = (cohorts) => {
  const targetCohortIds = ['65d7b8712ac83c4df407d6db', '65e258980123b64b13b6d22d']; // [development, production]
  return cohorts.some((cohort) => targetCohortIds.includes(cohort.cohortId));
};

/**
 * Retrieves the redirect URL from local storage and constructs the new path with any search parameters.
 *
 * @return {string|null} The new path with any search parameters, or null if the destinationURL does not have a pathname.
 */
export const getRedirectURLLocalStorage = () => {
  const destinationURL = JSON.parse(localStorage.getItem('destinationURL')) ?? {};
  if (destinationURL.pathname) {
    const destinationParams = new URLSearchParams();
    Object.keys(destinationURL.searchParams).forEach((p) => destinationParams.set(p, destinationURL.searchParams[p]));
    const newPath = destinationParams.toString()
      ? `${destinationURL.pathname}?${destinationParams.toString()}`
      : destinationURL.pathname;
    return newPath;
  }
  return null;
};

export const setRedirectURLLocalStorage = () => {
  const currentUrl = new URL(window.location.href);
  const params = Object.fromEntries(currentUrl.searchParams.entries());
  localStorage.setItem('destinationURL', JSON.stringify({ pathname: currentUrl.pathname, searchParams: params }));
};

export const removeRedirectURLLocalStorage = () => {
  localStorage.removeItem('destinationURL');
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const COMMENT_TRUNCATE_LEN_LONG = 50;
export const COMMENT_TRUNCATE_LEN_SHORT = 15;
