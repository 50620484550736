import { defineVariants } from 'zl-shared/dist/ab-test';

const EXPERIMENTS = {
  control: {
    name: 'control',
    variants: [
      { name: 'a', weight: 1 },
      { name: 'b', weight: 1 },
    ],
  },
  dailyCredits: {
    name: 'daily_credits_limit_202408',
    variants: [
      { name: '311-control', weight: 0 },
      { name: '422-slightly-increased', weight: 1 },
      { name: '555-increased', weight: 1 },
    ],
  },
} as const;

Object.values(EXPERIMENTS).forEach((experiment) => {
  defineVariants(
    experiment.name,
    experiment.variants.map((v) => v.name),
    experiment.variants.map((v) => v.weight),
  );
});

export type ExperimentKey = keyof typeof EXPERIMENTS;

type VariantOfExperiment<T extends ExperimentKey> = (typeof EXPERIMENTS)[T]['variants'][number]['name'];
export type ControlTestVariant = VariantOfExperiment<'control'>;
export type DailyCreditsTestVariant = VariantOfExperiment<'dailyCredits'>;

export default EXPERIMENTS;
