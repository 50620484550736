import { hashKey, QueryFunction, QueryKey } from '@tanstack/react-query';
import Pubnub from 'pubnub';

import { buildURL as baseBuildURL, handleResponse } from '@/shared/fetch';

import { Pagination, QueryParams } from './types';

const buildURL = (urlString: string, params?: QueryParams, page?: string | number) => {
  const query = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value != null) {
        if (Array.isArray(value)) {
          const arrayKey = `${key}[]`;
          value.forEach((val) => query.append(arrayKey, val.toString()));
        } else {
          query.set(key, value.toString());
        }
      }
    });
  }
  if (page != null) {
    if (typeof page === 'number') {
      query.set('page', page.toString());
    } else {
      query.set('cursor', page);
    }
  }
  return `${baseBuildURL(urlString)}${params || page != null ? '?' : ''}${query.toString()}`;
};

export const getQueryFn =
  <T, TQueryKey extends QueryKey = QueryKey>(urlString: string, params?: QueryParams): QueryFunction<T, TQueryKey> =>
  (context) => {
    const url = buildURL(urlString, params);
    return fetch(url, {
      signal: context.signal,
    }).then(handleResponse) as Promise<T>;
  };

export const getInfiniteQueryFn =
  <T, TQueryKey extends QueryKey = QueryKey>(
    urlString: string,
    params?: QueryParams,
  ): QueryFunction<Pagination<T>, TQueryKey, string | number> =>
  (context) => {
    const url = buildURL(urlString, params, context.pageParam);
    return fetch(url, {
      signal: context.signal,
    }).then(handleResponse) as Promise<Pagination<T>>;
  };

export const queryKeyHashFnForPubnub: typeof hashKey = (queryKey) =>
  hashKey(queryKey.map((val) => (val instanceof Pubnub ? 'pubnub' : val)));
